/**
 * Checks for a given content if any of its keys has a auth/no auth context
 * If `auth` key exists then it returns the relative value for that field
 * If not it returns the copy of content without manipulation
 * Only check for object type structures
 */
function getAuthValues<T>(content: T, isAuthenticated: boolean): T {
  const contentCopy = { ...content };
  // @ts-ignore
  Object.keys(content).forEach((key) => {
    // @ts-ignore
    const field = content[key];
    if (typeof field !== 'object') {
      return;
    }
    if (field.auth !== undefined) {
      // @ts-ignore
      contentCopy[key] = isAuthenticated ? field.auth : field.unauth;
    }
  });

  return contentCopy;
}

export default getAuthValues;

import appConfig from '@nerdwallet/app-config';
import { getLogger } from '@nerdwallet/logging';
import { getCookie } from '../cookies';

import useAnalyticsContext from './useAnalyticsContext';

const segmentTrackingLogger = getLogger('SegmentTracking');

type DefaultPayload = {
  /* eslint-disable-next-line camelcase */
  deployable_name: string;
  /* eslint-disable-next-line camelcase */
  deployable_version: string;
};

const useAnalytics = () => {
  const { analytics } = useAnalyticsContext();
  const defaultPayload: DefaultPayload = {
    // eslint-disable-next-line no-underscore-dangle
    deployable_name: appConfig?._meta?.deployable,
    // eslint-disable-next-line no-underscore-dangle
    deployable_version: appConfig?._meta?.version,
  };

  const trackSegmentTrackEvent = (
    eventName: string,
    eventProps: Record<string, any> = {},
  ) => {
    try {
      analytics?.track(eventName, {
        ...defaultPayload,
        ...eventProps,
        url: window?.location?.href,
      });
    } catch (e) {
      segmentTrackingLogger.error(e);
    }
  };

  const trackSegmentTrackLinkEvent = (
    element: HTMLElement,
    eventName: string,
    eventProps: Record<string, any> = {},
  ) => {
    try {
      analytics?.trackLink(element, eventName, {
        ...defaultPayload,
        ...eventProps,
        url: window?.location?.href,
      });
    } catch (e) {
      segmentTrackingLogger.error(e);
    }
  };

  const trackSegmentPageEvent = (
    pageName: string,
    pageViewId: string,
  ): void => {
    try {
      analytics?.page(pageName, {
        ...defaultPayload,
        pageview_id: pageViewId,
        traditional_device_id: getCookie('cookie_id'),
        url: window?.location?.href,
      });
    } catch (e) {
      segmentTrackingLogger.error(e);
    }
  };

  const segment: {
    track: (
      event: string,
      properties?: Record<string, unknown>,
    ) => Promise<void>;
    trackLink: (
      element: HTMLElement,
      event: string,
      properties?: Record<string, unknown>,
    ) => Promise<void>;
  } = {
    track: async (event, properties) => {
      trackSegmentTrackEvent(event, properties);
    },
    trackLink: async (element, event, properties) => {
      trackSegmentTrackLinkEvent(element, event, properties);
    },
  };

  return {
    trackSegmentTrackEvent,
    trackSegmentTrackLinkEvent,
    trackSegmentPageEvent,
    analytics: segment,
  };
};

export default useAnalytics;

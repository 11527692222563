'use client';

import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react';

// @ts-ignore
// import GlobalMarkupOptions from '@nerdwallet/coin/GlobalMarkupOptions';
import appConfig from '@nerdwallet/app-config';
import useAnalytics from './useAnalytics';
import usePageViewId from '~/app/lib/segment/usePageViewId';

interface SegmentTrackingContextValue {
  analytics: any;
  deployableName: string;
  deployableVersion: string;
  pageViewId: string;
}

/**
 * Context to provide pageview_id and deployable info to other components that
 * need it for Segment events.
 */
export const SegmentTrackingContext =
  createContext<SegmentTrackingContextValue | null>(null);

type SegmentTrackingProps = {
  segmentPageName: string;
};

const SegmentTracking = ({
  children,
  segmentPageName,
}: PropsWithChildren<SegmentTrackingProps>): JSX.Element => {
  const { analytics, trackSegmentPageEvent } = useAnalytics();
  const [isAvailable, setIsAvailable] = useState(false);
  const pageViewId = usePageViewId();

  useEffect(() => {
    const timeout = setInterval(() => {
      const setHeaderPropsIsAvailable =
        typeof window !== undefined &&
        // @ts-ignore
        typeof window.nerdwallet?.ui?.setHeaderProps === 'function';
      if (setHeaderPropsIsAvailable) {
        setIsAvailable(true);
        clearInterval(timeout);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (!isAvailable) return;

    // @ts-ignore
    window.nerdwallet?.ui?.setHeaderProps({
      segmentTrackingAttributes: {
        driverPageViewId: pageViewId,
        driverPageName: segmentPageName,
        deployableName: appConfig?._meta.deployable,
        deployableVersion: appConfig?._meta.version,
      },
    });
  }, [pageViewId, isAvailable, segmentPageName]);

  useEffect(() => {
    trackSegmentPageEvent(segmentPageName, pageViewId);
  }, [pageViewId, segmentPageName]);

  return (
    <div>
      {/*<GlobalMarkupOptions>*/}
      {/*  {{*/}
      {/*    segmentTrackingAttributes: {*/}
      {/*      driverPageName: segmentPageName,*/}
      {/*      // eslint-disable-next-line no-underscore-dangle*/}
      {/*      deployableName: appConfig?._meta?.deployable,*/}
      {/*      // eslint-disable-next-line no-underscore-dangle*/}
      {/*      deployableVersion: appConfig?._meta?.version,*/}
      {/*    },*/}
      {/*  }}*/}
      {/*</GlobalMarkupOptions>*/}
      <SegmentTrackingContext.Provider
        value={{
          analytics,
          deployableName: appConfig?._meta.deployable,
          deployableVersion: appConfig?._meta.version,
          pageViewId,
        }}
      >
        {children}
      </SegmentTrackingContext.Provider>
    </div>
  );
};

export default SegmentTracking;

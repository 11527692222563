import appConfig from '@nerdwallet/app-config';
import { getCookie } from './cookies';
import { User } from '@segment/analytics-next';

export default function getPageUnloadData(pageviewId: string, user: User) {
  return {
    event: 'page_unloaded',
    properties: {
      url: window.location,
      pageview_id: pageviewId,
      traditional_device_id: getCookie('cookie_id'),
      deployable_name: appConfig?._meta?.deployable, // eslint-disable-line no-underscore-dangle
      deployable_version: appConfig?._meta?.version, // eslint-disable-line no-underscore-dangle
    },
    anonymousId: user.anonymousId(),
    userId: user.id(),
    type: 'track',
    writeKey: appConfig?.SEGMENT?.WRITE_KEY,
    messageId: `custom_unloader-${pageviewId}`, // eslint-disable-line camelcase
    context: {
      page: { url: window.location },
      userAgent: window.navigator.userAgent,
      locale: window.navigator.language,
      library: { name: 'custom_unloader', version: 'custom_unloader:0.0.1' },
    },
    integrations: { 'Segment.io': true },
    _metadata: {
      bundled: ['Segment.io'],
      unbundled: [],
      bundledIds: [],
    },
    timestamp: '',
    sentAt: '',
  };
}

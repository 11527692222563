import React, { Fragment } from 'react';

import { Accent, Box, Stack, Text, Title } from '@nerdwallet/currency';

import { Article } from '~/generated-graphql/generated-types';
import { trackInteraction } from '~/app/lib/analytics/analytics-helpers';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '~/app/lib/analytics/analytics-constants';
import NewsArticlesAuthor from '../news-articles-author/news-articles-author';
import styles from './news-articles-sidebar.module.scss';
import useAnalytics from '~/app/lib/segment/useAnalytics';

type NewsArticlesSidebarProps = {
  articles: Array<Article>;
};

const NewsArticlesSidebar = ({
  articles,
}: NewsArticlesSidebarProps): JSX.Element => {
  const { trackSegmentTrackEvent } = useAnalytics();

  return (
    <Fragment>
      <Box className={styles.accentWrapper} marginBottom="3">
        <Accent>Latest Financial News</Accent>
      </Box>
      <Stack spacing={0}>
        {articles.map((article: Article, i: number) => (
          <Box
            borderColor="green-lighter"
            borderTop="1"
            className={styles.articleRow}
            key={article.id}
            paddingBottom="3-half"
            paddingTop="3"
          >
            <Title className={styles.articleNumber}>{i + 1}</Title>
            <Box>
              <a
                className={styles.articleLink}
                href={article.link}
                onClick={() => {
                  trackInteraction({
                    entityName: `breaking_news_feed_sidebar_${article.id}`,
                    sectionName: analyticsBase.section_name,
                    action: 'select',
                  });
                  trackSegmentTrackEvent('Element Interaction', {
                    entity_name: `breaking_news_feed_sidebar_${article.id}`,
                    location:
                      HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
                    action: 'select',
                  });
                }}
              >
                <div className={styles.articleLink}>{article.title}</div>
              </a>
              <Box marginTop="2">
                <NewsArticlesAuthor
                  avatarUrl={article?.authors?.[0]?.avatarUrls?.[0]?.url}
                  authorUrl={article?.authors?.[0]?.link}
                  name={article?.authors?.[0]?.name ?? 'NerdWallet'}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>
    </Fragment>
  );
};

NewsArticlesSidebar.displayName = 'NewsArticlesSidebar';

export default NewsArticlesSidebar;

import { useEffect } from 'react';
import useAnalyticsContext from '../lib/segment/useAnalyticsContext';
import usePageViewId from '../lib/segment/usePageViewId';
import getPageUnloadData from '../lib/getPageUnloadData';

export default function useInitializeBounceTracking(): void {
  const { analytics } = useAnalyticsContext();
  const pageviewId = usePageViewId();

  const initializeBounceTracking = async (): Promise<void> => {
    if (analytics) {
      const user = await analytics.user();
      const pageUnloadData = getPageUnloadData(pageviewId, user);

      window.addEventListener('unload', function () {
        // apply only to homepage
        if (window.location.pathname === '/') {
          pageUnloadData.timestamp = new Date().toISOString();
          pageUnloadData.sentAt = pageUnloadData.timestamp;

          navigator.sendBeacon(
            'https://siteusage.nerdwallet.com/v1/p',
            JSON.stringify(pageUnloadData),
          );
        }
      });
    }
  };

  useEffect(() => {
    initializeBounceTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

import React from 'react';
import { Accent, Box } from '@nerdwallet/currency';

import styles from './new-feature-tag.module.scss';

interface Props {
  textSize?: React.ComponentProps<typeof Accent>['size'];
  classNames?: string;
  color?: string;
}

const NewFeatureTag: React.FC<Props> = ({
  textSize,
  classNames = '',
  color = '#02427E',
}) => (
  <Box className={`${styles.newText} ${classNames}`}>
    <Accent size={textSize}>new</Accent>
    <svg
      className={styles.sparkle}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        d="M12.81 14.997a.107.107 0 00.103-.107c.013-.854.186-1.7.51-2.49.336-.822.83-1.569 1.452-2.198a6.693 6.693 0 012.173-1.469 6.631 6.631 0 012.564-.515c.517 0 .517-.109 0-.109-.88 0-1.751-.175-2.564-.515a6.693 6.693 0 01-2.173-1.47 6.782 6.782 0 01-1.453-2.197 6.843 6.843 0 01-.509-2.488.107.107 0 00-.107-.106.107.107 0 00-.107.106 6.842 6.842 0 01-.507 2.49 6.782 6.782 0 01-1.452 2.2A6.694 6.694 0 018.566 7.6 6.632 6.632 0 016 8.117c-.446 0-.446.108 0 .108.88 0 1.75.175 2.563.516.813.34 1.552.84 2.174 1.468a6.782 6.782 0 011.452 2.199c.323.788.496 1.63.51 2.483 0 .06.05.108.11.107zM4.485 19a.062.062 0 00.062-.061 4.008 4.008 0 011.14-2.745 3.856 3.856 0 012.753-1.162c.302 0 .302-.064 0-.064a3.856 3.856 0 01-2.753-1.162 4.008 4.008 0 01-1.14-2.745.062.062 0 00-.062-.061.062.062 0 00-.061.061c-.008.5-.108.995-.296 1.458a3.973 3.973 0 01-.844 1.287c-.362.369-.791.66-1.263.86-.473.2-.979.302-1.49.302-.263 0-.263.064 0 .064.511 0 1.017.102 1.49.302.472.2.901.491 1.263.86.361.368.648.806.844 1.287.188.463.288.957.296 1.458 0 .034.027.061.061.061z"
      />
    </svg>
  </Box>
);

export default NewFeatureTag;

'use client';

import useInitializeBounceTracking from '../hooks/useInitializeBounceTracking';

function BounceTracking() {
  useInitializeBounceTracking();

  return null;
}

export default BounceTracking;

import { getLogger } from '@nerdwallet/logging';

const rootLogger = getLogger('front-page');
const graphqlLogger = getLogger('GraphQL', rootLogger);

export const logGqlError = (error) => {
  graphqlLogger.error(error?.message, error);
};

export default rootLogger;

export const logGqlInfo = (info) => {
  graphqlLogger.debug(info);
};

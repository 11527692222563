export { default as announcementBannerContent } from './front-page-content-src/announcement-banner';
export {
  verticalComparisonContent,
  byYourSideContent,
} from './front-page-content-src/verticalComparisonContent';
export { default as ccBestContent } from './front-page-content-src/cc-best-content';
export { default as securityContent } from './front-page-content-src/security-content';
export { default as testimonialsContent } from './front-page-content-src/testimonials-content';
export { default as registrationModuleContent } from './front-page-content-src/registration-module-content';
export { default as campaignHighlightContent } from './front-page-content-src/campaign-highlight-content';

import React from 'react';
import classNames from 'classnames';
import Image from '@nerdwallet/react-image';
import { Text, Cluster, Link } from '@nerdwallet/currency';
import styles from './news-articles-author.module.scss';

type NewsArticlesAuthorProps = {
  authorUrl: string;
  avatarUrl: string;
  name: string;
};

const NewsArticlesAuthor = ({
  authorUrl,
  avatarUrl,
  name,
}: NewsArticlesAuthorProps): JSX.Element => (
  <Link href={authorUrl}>
    <Cluster spacing={2} alignY="center">
      {avatarUrl ? (
        <Image
          lazy
          src={avatarUrl}
          backgroundColor="transparent"
          imageClassName={classNames(
            styles.authorAvatarImage,
            styles.overlayOnHover,
          )}
          className={styles.authorAvatarPreload}
          naturalWidth={32}
          naturalHeight={32}
          alt=""
        />
      ) : null}

      <Text size="x-small" light>
        by {name}
      </Text>
    </Cluster>
  </Link>
);

export default NewsArticlesAuthor;

import React from 'react';
import { Box } from '@nerdwallet/currency';

import styles from './nw-plus-tag.module.scss';

interface Props {
  classNames?: string;
  color?: string;
}

const NwPlusTag: React.FC<Props> = ({ classNames = '' }) => (
  <Box className={`${styles.newText} ${classNames}`}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="20"
      fill="none"
      viewBox="0 0 38 20"
    >
      <path
        d="M7.50941 0.0401845C7.43953 0.0148832 7.36509 0 7.28913 0H1.19295C0.945334 0 0.744812 0.196458 0.744812 0.439053V6.66617L7.50941 0.0401845Z"
        fill="#00AE4D"
      />
      <path
        d="M0.875427 19.872C0.95594 19.9509 1.06835 20 1.19292 20H7.10074C7.34835 20 7.54888 19.8036 7.54888 19.561V13.3339L0.875427 19.872Z"
        fill="#00AE4D"
      />
      <path
        d="M14.3545 6.66614V19.9434C14.3682 19.9494 14.3818 19.9553 14.3955 19.9598L21.1601 13.3323L14.356 6.66614H14.3545Z"
        fill="#00682C"
      />
      <path
        d="M7.5489 13.3338V0.0565561C7.53523 0.0506028 7.52156 0.0446496 7.50789 0.0386963L0.744812 6.66617V19.5609C0.744812 19.6815 0.794943 19.7916 0.875455 19.872L7.5489 13.3338Z"
        fill="#8DC63F"
      />
      <path
        d="M21.1586 13.3338V6.66766V0.439053C21.1586 0.196458 20.9581 0 20.7104 0H14.8026C14.555 0 14.3545 0.196458 14.3545 0.437565V6.66617L21.1586 13.3323V13.3338Z"
        fill="#8DC63F"
      />
      <path
        d="M21.1586 13.3339L14.394 19.9613C14.4639 19.9866 14.5384 20.0015 14.6143 20.0015H20.7105C20.9581 20.0015 21.1586 19.8051 21.1586 19.5625V13.3354V13.3339Z"
        fill="#8DC63F"
      />
      <path
        d="M7.7327 0.180049C7.67953 0.127958 7.61573 0.0862849 7.54889 0.0565186V13.3338L14.1692 19.8199C14.2223 19.872 14.2861 19.9136 14.353 19.9434V6.66613L7.7327 0.180049Z"
        fill="#00AE4D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.7662 8.22891V12.9766C32.7662 13.141 32.6301 13.2743 32.4623 13.2743H29.8221C29.6543 13.2743 29.5183 13.141 29.5183 12.9766V8.22891H24.6723C24.5046 8.22891 24.3685 8.09564 24.3685 7.93125V5.34456C24.3685 5.18016 24.5046 5.04689 24.6723 5.04689H29.5183V0.297663C29.5183 0.133268 29.6543 0 29.8221 0H32.4623C32.6301 0 32.7662 0.133268 32.7662 0.297663V5.04689H37.6136C37.7814 5.04689 37.9175 5.18016 37.9175 5.34455V7.93125C37.9175 8.09564 37.7814 8.22891 37.6136 8.22891H32.7662Z"
        fill="#F8991D"
      />
    </svg>
  </Box>
);

export default NwPlusTag;

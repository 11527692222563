import React, { FC, ReactNode } from 'react';
import { InView } from 'react-intersection-observer';

import useAnalytics from '~/app/lib/segment/useAnalytics';

interface SegmentImpressionProps {
  children: ReactNode;
  className?: string;
  eventName: string;
  eventProps?: Record<string, any>;
}

const SegmentImpression: FC<SegmentImpressionProps> = ({
  children,
  className,
  eventName,
  eventProps = {},
}) => {
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleViewChange = (inView: boolean) => {
    if (inView) {
      trackSegmentTrackEvent(eventName, eventProps);
    }
  };

  return (
    <InView className={className} onChange={handleViewChange} triggerOnce>
      {children}
    </InView>
  );
};

export default SegmentImpression;

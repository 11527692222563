import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ControlledTabs, useTabState, Box, Text, Title } from '@nerdwallet/currency';
import {
  trackImpression,
  trackInteraction,
} from '~/app/lib/analytics/analytics-helpers';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '../../../lib/analytics/analytics-constants';
import VerticalComparisonTab from './vertical-comparison-tab';
import styles from './vertical-comparison.module.scss';
import useAnalytics from '../../../lib/segment/useAnalytics';
import SegmentImpression from '../../segment-impression';

const IconAndTitle = ({ title, icon }) => {
  const Icon = icon;
  return (
    <div>
      <Box paddingBottom={2}>
        <Icon size={35} />
      </Box>
      <span className={styles.iconTitleStyle}>{title}</span>
    </div>
  );
};
const VerticalComparisonNoTyping = ({ title, subtitle, tabs, backgroundImageUrl }) => {
  const mappedTabs = tabs.map((tab) => ({
    label: <IconAndTitle title={tab.title} icon={tab.icon} />,
    id: tab.id,
    content: <VerticalComparisonTab {...tab} />,
  }));
  const { trackSegmentTrackEvent } = useAnalytics();
  const tabState = useTabState({
    tabs: mappedTabs,
    baseId: 'verticalComparisonTab',
  });
  const previousSelectedIndexForTracking = useRef(tabState.selectedIndex);
  const handleTabChange = (entityName) => {
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison.location,
      interaction_type: 'select',
    });
    trackSegmentTrackEvent('Element Impression', {
      entity_name: entityName,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison.location,
    });
  };
  useEffect(() => {
    const selectedLabel = tabs?.[tabState.selectedIndex]?.id;
    if (selectedLabel) {
      const entityName = `${analyticsBase.entity_names.tabs}_${selectedLabel}`;
      // Storing the previous index in a ref allows us to avoid firing an event
      // on the initial render, as the "previous" index will match the "current"
      // index because of how this ref is initialized.
      if (previousSelectedIndexForTracking.current !== tabState.selectedIndex) {
        trackInteraction({
          entityName,
          sectionName: 'homepage',
          action: 'select',
        });
        previousSelectedIndexForTracking.current = tabState.selectedIndex;
      }
      trackImpression({
        entityName,
        sectionName: analyticsBase.section_name,
      });
    }
  }, [tabs, tabState.selectedIndex]);
  const entityName = `${analyticsBase.entity_names.tabs}_${
    tabs?.[tabState.selectedIndex]?.id
  }`;
  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: entityName,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.vertical_comparison.location,
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.titleWrap}>
            <Title default component="h1">
              <span className={styles.titleSize}>
                {title}
              </span>
            </Title>
          </div>
          <div className={styles.subtitleWrap}>
            <Text>{subtitle}</Text>
          </div>
          <div className={styles.tabsWrapper}>
            <ControlledTabs
              hasSpacingBetweenTabsAndContent={false}
              hasBorderBetweenTabsAndContent={true}
              tabState={tabState}
              onChange={() => handleTabChange(entityName)}
            />
          </div>
        </div>
      </div>
    </SegmentImpression>
  );
};
IconAndTitle.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.elementType,
};
VerticalComparisonNoTyping.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape(VerticalComparisonNoTyping.propTypes),
  ),
};
VerticalComparisonNoTyping.default = {};

export default VerticalComparisonNoTyping;

import React from 'react';
import { Divider } from '@nerdwallet/currency';
import _ from 'lodash';
// @ts-ignore
import Carousel from '@nerdwallet/react-carousel';
// @ts-ignore
import { Impression } from '@nerdwallet/analytics/react/impression';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '~/app/lib/analytics/analytics-constants';
import SegmentImpression from '../../../segment-impression';
import SecondaryCampaignCard, {
  CampaignCardProps,
} from '../secondary-campaign-card/secondary-campaign-card';
import styles from './secondary-campaign-carousel.module.scss';

interface SecondaryCampaignCarouselProps {
  tiles: CampaignCardProps[];
}

const SecondaryCampaignCarousel: React.FC<SecondaryCampaignCarouselProps> = ({
  tiles,
}) => {
  const renderedTiles = _.map(tiles, (tile, nav) => (
    <SecondaryCampaignCard {...tile} key={`item-${nav}`} />
  ));

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={
        HOMEPAGE_SEGMENT_INSTRUMENTATION.secondary_campaign_module_carousel
      }
    >
      <Impression
        entityName={analyticsBase.entity_names.secondary_campaign_module}
        payload={{
          section_name: analyticsBase.section_name,
          registration_cohort: analyticsBase.cohort,
        }}
      >
        <div className={styles.secondaryCampaignCarouselContainer}>
          <Carousel
            id="secondary-campaign"
            entityName="secondary-campaign"
            decorateNav={false}
            enableDotNavigation
            arrows={false}
            dots
            inactiveDotColor="#000000"
            activeDotColor="#008255"
            slidesToShow="1.3"
            className={styles.secondaryCampaignCarousel}
            suppressHydrationWarning
          >
            {renderedTiles}
          </Carousel>
          <Divider />
        </div>
      </Impression>
    </SegmentImpression>
  );
};

SecondaryCampaignCarousel.displayName = 'SecondaryCampaignCarousel';

export default SecondaryCampaignCarousel;

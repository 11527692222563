import { useEffect, useState } from 'react';

function useIsAuthenticated() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const authenticated: boolean = document?.cookie?.includes(
      'member_identity_assertion_token=',
    );
    setIsAuthenticated(authenticated);
  }, []);

  return isAuthenticated;
}

export default useIsAuthenticated;

import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';

/**
 * Hook that returns a unique pageview_id for Segment
 */
const getPageViewId = uuidv4();

const usePageViewId = (): string => {
  const pageViewId = useRef(getPageViewId);
  return pageViewId.current;
};

export default usePageViewId;

import { NW_PLUS } from '../paths';

export default {
  text: {
    auth: 'Earn up to $350/year in rewards for your smart financial decisions with NerdWallet+.',
    unauth:
      'Earn up to $350/year in rewards for your smart financial decisions with NerdWallet+.',
  },
  textMobile: {
    auth: 'Earn up to $350/year in rewards for your smart financial decisions.',
    unauth:
      'Earn up to $350/year in rewards for your smart financial decisions+.',
  },
  ctaText: 'Learn more',
  ctaTextMobile: 'Learn about NerdWallet+',
  ctaLink: NW_PLUS,
  campaign: 'NW_PLUS',
  campaignKey: 'nw_plus_ribbon',
};

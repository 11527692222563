export default {
  eyebrow: "we've done the homework",
  heading: "See our picks for this month's top credit cards",
  links: [
    {
      label: 'Balance transfer',
      href: '/best/credit-cards/balance-transfer/?trk=hp_bestcards_balancetransfercards&trk_copy=hpbalancetransfercards',
      emojiCodePoint: [0x2696, 0xfe0f],
    },
    {
      label: 'Low interest',
      href: '/best/credit-cards/low-interest/?trk=hp_bestcards_lowinterestcards&trk_copy=hplowinterestcards',
      emojiCodePoint: [0x1f64c],
    },
    {
      label: 'Travel',
      href: '/best/credit-cards/travel/?trk=hp_bestcards_travelcards&trk_copy=hptravelcards',
      emojiCodePoint: [0x1f3d6, 0xfe0f],
    },
    {
      label: 'Cash back',
      href: '/best/credit-cards/cash-back/?trk=hp_bestcards_cashbackcards&trk_copy=hpcashbackcards',
      emojiCodePoint: [0x1f4b0],
    },
    {
      label: 'Rewards',
      href: '/best/credit-cards/rewards/?trk=hp_bestcards_rewardscards&trk_copy=hprewardscards',
      emojiCodePoint: [0x1f381],
    },
  ],
};

import { HOMEPAGE as analyticsBase } from '../analytics/analytics-constants';

export interface CampaignHighlight {
  title: string;
  subtitle: string;
  titleEnd: string;
  backgroundColor: string;
  ctaButtonText: string;
  ctaLinkUrl: string;
  titlePreColor: string;
  titleEndColor: string;
  campaignHighlightFooterCopy: string;
  campaignHighlightFooterCopyColor: string;
  visualBackgroundImageDesktopUrl: string;
  visualBackgroundImageMidUrl: string;
  visualBackgroundImageTabletUrl: string;
  visualBackgroundImageMobileUrl: string;
  visualBackgroundImageAlt: string;
  nwplusFullLogo: string;
  nwplusFullLogoAlt: string;
  trkQueryParam: string;
}

const content: CampaignHighlight = {
  title: 'Get up to $425/year in cash rewards and savings with NerdWallet+',
  subtitle:
    'It pays to be smarter with your finances. NerdWallet+ members earn rewards for smart financial decisions and save with exclusive access to financial products and tools.¹',
  titleEnd: '',
  backgroundColor: '#E8FDAC',
  ctaButtonText: 'LEARN MORE',
  ctaLinkUrl: 'lp/nerdwallet-plus-main',
  titlePreColor: '#000000',
  titleEndColor: '#000000',
  campaignHighlightFooterCopy: '',
  campaignHighlightFooterCopyColor: '',
  visualBackgroundImageDesktopUrl: `/nwplus-module-desktop.png`,
  visualBackgroundImageMidUrl: `/nwplus-module-mid.png`,
  visualBackgroundImageTabletUrl: `/nwplus-module-tablet.png`,
  visualBackgroundImageMobileUrl: `/nwplus-module-mobile-v2.png`,
  visualBackgroundImageAlt: 'NerdWallet+ App showing a 2,500 pts rewards balance',
  nwplusFullLogo: `/nwplus_full_logo.png`,
  nwplusFullLogoAlt: 'NerdWallet Plus Logo',
  trkQueryParam: analyticsBase.entity_names.campaign, // used to append to the URL when the button is clicked,
};

export default content;

import appConfig from '@nerdwallet/app-config';

export const HOMEPAGE = {
  section_name: 'homepage',
  cohort: 'financial_clarity',
  entity_names: {
    tabs: 'vertical_comparison_tab',
    primary_cta: 'primary_cta_button',
    secondary_cta: 'secondary_cta_button',
    registration: 'registration_module',
    testimonials: 'testimonials_module',
    cc_best: 'best_credit_card_module',
    campaign: 'nwplus_rewards_campaign_module',
    secondary_campaign_module: 'nw_advisors_secondary_campaign_module',
    breaking_news_feed: 'breaking_news_feed',
    auth_buttons: 'auth_button',
    success: 'success_modal',
    error: 'error_modal',
  },
  trk: {
    trk_location: 'homepage',
    trk_format: 'financial_clarity',
  },
  additional_attributes: {
    target_flow: 'no_security_question',
    cta_mode: 'modal',
    cta_label: 'continue_with',
    cta_format: 'button',
    cta_format_link: 'link',
    auth_type: 'register',
  },
  segmentTrackingAttributes: {
    driverPageName: 'homepage',
    driverLocation: 'homepage',
    driverSubLocation: 'financial_clarity',
    // eslint-disable-next-line no-underscore-dangle
    deployableName: appConfig?._meta?.deployable,
    // eslint-disable-next-line no-underscore-dangle
    deployableVersion: appConfig?._meta?.version,
  },
};

export const HOMEPAGE_SEGMENT_INSTRUMENTATION = {
  campaign: {
    entity_name: 'nwplus_rewards_campaign_module',
    location: 'homepage',
    sub_location: 'campaign_module',
  },
  secondary_campaign_module: {
    nw_taxes: {
      entity_name: 'nw_taxes',
      location: 'homepage',
      sub_location: 'secondary_campaign_module',
    },
    nw_advisors: {
      entity_name: 'nw_advisors',
      location: 'homepage',
      sub_location: 'secondary_campaign_module',
    },
  },
  secondary_campaign_module_carousel: {
    entity_name: 'nw_advisors',
    location: 'homepage',
    sub_location: 'secondary_campaign_module',
  },
  cc_best: {
    entity_name: 'best_credit_card_module',
    location: 'homepage',
    sub_location: 'financial_clarity',
  },
  breaking_news: {
    entity_name: 'breaking_news_feed',
    location: 'homepage',
  },
  hero_module: {
    entity_name: 'nwplus_takeover_hero_module',
    location: 'homepage',
    sub_location: 'hero_module',
  },
  registration_module: {
    entity_name: 'registration_module',
    location: 'homepage',
    registration_cohort: 'financial_clarity',
  },
  testimonials: {
    entity_name: 'testimonials_module',
    location: 'homepage',
    registration_cohort: 'financial_clarity',
  },
  carousel_testimonials: {
    entity_name: 'carousel_testimonials',
    location: 'homepage',
    sub_location: 'testimonials_module',
  },
  vertical_comparison: {
    entity_names: {
      tabs: 'vertical_comparison_tab',
      primary_cta: 'primary_cta_button',
      secondary_cta: 'secondary_cta_button',
    },
    location: 'homepage',
  },
};

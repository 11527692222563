export const pageTaxonomies = {
  pressPage: [
    { nwVarName: 'vertical', bkVarName: 'vertical', value: 'press' },
    { nwVarName: 'topic', bkVarName: 'topic', value: '' },
    { nwVarName: 'pageType', bkVarName: 'pageType', value: 'Navigate' },
  ],
  frontPage: [
    { nwVarName: 'vertical', bkVarName: 'vertical', value: 'Homepage' },
    { nwVarName: 'topic', bkVarName: 'topic', value: '' },
    { nwVarName: 'pageType', bkVarName: 'pageType', value: 'Navigate' },
  ],
  companyPage: [
    { nwVarName: 'vertical', bkVarName: 'vertical', value: 'company' },
    { nwVarName: 'topic', bkVarName: 'topic', value: '' },
    { nwVarName: 'pageType', bkVarName: 'pageType', value: 'Navigate' },
  ],
};

const pathTaxonomyMap = {
  '/': 'frontPage',
  '/careers': 'companyPage',
  '/careers/teams': 'companyPage',
  '/company': 'companyPage',
  '/corporate-social-responsibility': 'companyPage',
  '/corporate-social-responsibility/email': 'companyPage',
  '/kit': 'pressPage',
  '/leadership': 'companyPage',
  '/news': 'pressPage',
  '/news/archive': 'pressPage',
  '/press': 'pressPage',
  '/press-releases': 'pressPage',
  '/specialists': 'pressPage',
  '/privacy-policy': 'companyPage',
  '/diversity-inclusion': 'companyPage',
};

export function getPageTaxonomy(path) {
  return pageTaxonomies[pathTaxonomyMap[path]];
}

export function getTaxonomyObject(taxonomyArray) {
  const taxonomyObject = {};
  taxonomyArray.forEach((taxonomyItem) => {
    taxonomyObject[taxonomyItem.nwVarName] = taxonomyItem.value;
  });
  return taxonomyObject;
}

import appConfig from '@nerdwallet/app-config';
import { HOMEPAGE as analyticsBase } from '../analytics/analytics-constants';

export interface Badge {
  url: string;
  height: number;
  width: number;
  top: boolean;
  alt: string;
}

export interface SingleLayoutProps {
  titleImage: string;
  campaignHighlightFooterCopy: string;
  campaignHighlightFooterCopyColor: string;
  visualBackgroundImageMobileUrl: string;
  visualBackgroundImageUrl: string;
  backgroundColor: string;
  backgroundColorMobile: string;
}

export interface CampaignCardProps {
  badge: Badge;
  subtitle: string;
  ctaButtonText: string;
  ctaLinkUrl: string;
  elementImpressionEntityName: string;
  elementImpressionSectionName: string;
  instrumentationKey: string;
  trkQueryParam: string;
  singleLayout?: SingleLayoutProps;
}

const cards: CampaignCardProps[] = [
  {
    badge: {
      url: `${appConfig.ASSETS_URL}/images/homepage/nw_advisors_badge.png`,
      height: 17,
      width: 211,
      top: false,
      alt: 'NW ADVISORS',
    },
    subtitle: 'Get matched with a trusted financial advisor for free',
    ctaButtonText: 'FIND AN ADVISOR',
    ctaLinkUrl: 'https://match.nerdwalletadvisors.com/find-my-advisor',
    elementImpressionEntityName:
      analyticsBase.entity_names.secondary_campaign_module, // used for the Impression of the component
    elementImpressionSectionName: analyticsBase.section_name,
    instrumentationKey: 'nw_advisors',
    trkQueryParam: analyticsBase.entity_names.secondary_campaign_module,
    singleLayout: {
      titleImage: `${appConfig.ASSETS_URL}/images/homepage/nw_advisors_match.png`,
      campaignHighlightFooterCopy: 'on NerdWallet Advisors Match',
      campaignHighlightFooterCopyColor: '#3D4045',
      visualBackgroundImageMobileUrl: `${appConfig.ASSETS_URL}/images/homepage/nwplus_campaign_advisor_mobile_v2.jpg`,
      visualBackgroundImageUrl: `${appConfig.ASSETS_URL}/images/homepage/nw_plus_campaign_advisor_mobile.png`,
      backgroundColor: '#EEF7FF',
      backgroundColorMobile: '#DEEFFF',
    },
  },
];

export default cards;

export default {
  tiles: [
    {
      content:
        '"I\'ve always found NerdWallet extremely helpful when I\'ve been looking for good credit options and savings options! The information is very easy to understand!"',
      author: 'John W.',
      position: 'NerdWallet user',
      bgColor: '#FBD083',
    },
    {
      content:
        '"NerdWallet is on top of my finances with me. They advise the best possible move I can/could/should make, leaving the actual choice ultimately mine."',
      author: 'Brad N.',
      position: 'NerdWallet user',
      bgColor: '#FFF7EA',
    },
    {
      content:
        '"NerdWallet helped me with decisions concerning my finances and investing in ways other apps or search engines couldn\'t. Best part? It\'s free."',
      author: 'Dallas W.',
      position: 'NerdWallet user',
      bgColor: '#FBD083',
    },
  ],
};

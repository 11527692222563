import React from 'react';

import {
  Experiment,
  ExperimentControl,
  ExperimentVariant,
} from '@nerdwallet/features';

import { EXPERIMENTS } from '.';

const TEST_NAME = EXPERIMENTS.bannerExperiment.id;
const { VARIANT } = EXPERIMENTS.bannerExperiment.variants;

type Props = {
  children: JSX.Element;
};

const HomepageRemoveBannerMobile = ({ children }: Props): JSX.Element => {
  return (
    <Experiment testName={TEST_NAME}>
      <ExperimentControl>{children}</ExperimentControl>
      <ExperimentVariant name={VARIANT} />
    </Experiment>
  );
};

export default HomepageRemoveBannerMobile;

import analytics, {
  EventTypeElementImpression,
  EventTypeElementInteracted,
} from '@nerdwallet/analytics';

export const trackImpression = ({
  entityName,
  sectionName,
  registrationCohort,
  payload,
}: {
  entityName: EventTypeElementImpression['Payload']['entity_name'];
  sectionName?: EventTypeElementImpression['Payload']['section_name'];
  registrationCohort?: EventTypeElementImpression['Payload']['registration_cohort'];
  payload?: Record<string, unknown>;
}): void => {
  analytics.track<EventTypeElementImpression>('element_impression', {
    entity_name: entityName,
    section_name: sectionName,
    registration_cohort: registrationCohort,
    ...payload,
  });
};

export const trackInteraction = ({
  entityName,
  action,
  sectionName,
  registrationCohort,
}: {
  entityName: EventTypeElementInteracted['Payload']['entity_name'];
  action: EventTypeElementInteracted['Payload']['action'];
  sectionName?: EventTypeElementInteracted['Payload']['section_name'];
  registrationCohort?: EventTypeElementInteracted['Payload']['registration_cohort'];
}): void => {
  analytics.track<EventTypeElementInteracted>('element_interacted', {
    entity_name: entityName,
    section_name: sectionName,
    action,
    registration_cohort: registrationCohort,
    interaction_id: analytics.uuid(),
    interaction_type: 'press',
  });
};

import React from 'react';
import { Text, Link } from '@nerdwallet/currency';
// @ts-ignore
import { Impression } from '@nerdwallet/analytics/react/impression';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import nwAnalytics from '@nerdwallet/analytics';
import SegmentImpression from '../../../segment-impression';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import styles from './secondary-campaign-card.module.scss';
import {
  Badge,
  SingleLayoutProps,
} from '~/app/lib/front-page-content-src/secondary-campaign-module-content';

export interface CampaignCardProps {
  badge: Badge;
  subtitle: string;
  ctaButtonText: string;
  ctaLinkUrl: string;
  elementImpressionEntityName: string;
  elementImpressionSectionName: string;
  instrumentationKey: string;
  trkQueryParam: string;
  singleLayout?: SingleLayoutProps;
}

const SecondaryCampaignCard: React.FC<CampaignCardProps> = ({
  badge,
  subtitle,
  ctaButtonText,
  ctaLinkUrl,
  elementImpressionEntityName,
  elementImpressionSectionName,
  instrumentationKey,
  trkQueryParam,
}) => {
  const { trackSegmentTrackEvent } = useAnalytics();
  const analyticsObj =
    // @ts-ignore
    HOMEPAGE_SEGMENT_INSTRUMENTATION.secondary_campaign_module[
      instrumentationKey
    ];
  const handleCampaignLinkClick = () => {
    trackSegmentTrackEvent('Element Interaction', analyticsObj);
    nwAnalytics.track('element_interacted', {
      action: 'select',
      entity_name: analyticsObj.entityName,
      impression_id: uuidv4(),
      interaction_type: 'press',
    });
  };

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: analyticsObj.entity_name,
        location: analyticsObj.location,
      }}
    >
      <Impression
        entityName={elementImpressionEntityName}
        payload={{ section_name: elementImpressionSectionName }}
      >
        <div className={styles.cardContainer}>
          <img
            alt={badge.alt}
            src={badge.url}
            height={badge.height}
            width={badge.width}
            className={badge.top ? styles.topBadge : ''}
          />
          <div
            className={classNames(styles.text, {
              [styles.withTopBadge]: badge.top,
            })}
          >
            <Text>{subtitle}</Text>
          </div>
          <Link
            href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
            onClick={handleCampaignLinkClick}
            className={styles.link}
          >
            {ctaButtonText}
          </Link>
        </div>
      </Impression>
    </SegmentImpression>
  );
};

export default SecondaryCampaignCard;

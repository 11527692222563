import '../types/window.d';

interface UseInitializeGtmProps {
  pageTaxonomy: Array<{
    nwVarName: string;
    value: string;
  }>;
}

type MappedTaxonomy = {
  [key: string]: string | undefined;
};

const mapGtmVars = (pageTaxonomy: Record<string, any>[]) => {
  return pageTaxonomy.reduce((acc: MappedTaxonomy, item) => {
    acc[item?.nwVarName] = item.value;
    return acc;
  }, {});
};

let hasFired = false;
export const useInitializeGtm = ({ pageTaxonomy }: UseInitializeGtmProps) => {
  const mappedTaxonomy = mapGtmVars(pageTaxonomy);

  if (hasFired) {
    return;
  }

  if (typeof window === 'undefined') return;

  hasFired = true;
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(
    {
      event: 'gtm.load',
      gtmPageVertical: mappedTaxonomy.vertical ?? '',
      gtmQuantcastLabel: `Vertical.${mappedTaxonomy.vertical ?? 'undefined'}`,
      gtmPageTopic: mappedTaxonomy.topic ?? '',
      gtmPageType: mappedTaxonomy.pageType ?? '',
      gtmContentType: mappedTaxonomy.contentType ?? '',
      gtmContentClusters: mappedTaxonomy.contentClusters ?? '',
      isNWMobileApp: 'false',
      isLoggedIn: 'false',
      gtmAttrVertical: mappedTaxonomy.attrVertical ?? '',
      gtmAttrTopic: mappedTaxonomy.attrTopic ?? '',
      gtmPrimaryCluster: mappedTaxonomy.attrPrimaryCluster ?? '',

      // Below values have to wait for nwa to load, and are pushed separately:
      // gtmCookieId - for Sizmek & Facebook
      // gtmPageViewId - for Sizmek
    },
    {
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    },
  );
};

import React from 'react';
import { Box, Title } from '@nerdwallet/currency';
import _ from 'lodash';
import SecondaryCampaignModule from './secondary-campaign-module';
import SecondaryCampaignCard from './secondary-campaign-card/secondary-campaign-card';
import SecondaryCampaignCarousel from './secondary-campaign-carousel/secondary-campaign-carousel';
import useIsAuthenticated from '../../../hooks/isAuthenticated';
import getAuthValues from '../../../lib/front-page-content-src/getAuthValues';
import styles from './secondary-campaign-module-container.module.scss';
import {
  CampaignCardProps,
  SingleLayoutProps,
} from '~/app/lib/front-page-content-src/secondary-campaign-module-content';

interface SecondaryCampaignModuleContainerProps {
  title?: string;
  cards?: CampaignCardProps[];
}

const SecondaryCampaignModuleContainer: React.FC<
  SecondaryCampaignModuleContainerProps
> = ({ title, cards = [] }) => {
  const isAuthenticated = useIsAuthenticated();
  const shouldRenderSingleLayoutCard = cards.length === 1;

  if (shouldRenderSingleLayoutCard) {
    const singleLayoutCard = getAuthValues(
      cards.find((c) => c.singleLayout),
      isAuthenticated,
    );
    const singleLayoutProps =
      // @ts-ignore
      singleLayoutCard.singleLayout as SingleLayoutProps;
    const componentProps = {
      ..._.omit(singleLayoutCard, ['badge', 'singleLayout']),
      ...singleLayoutProps,
    };

    return (
      <div className={styles.container}>
        <SecondaryCampaignModule {...componentProps} />
      </div>
    );
  }

  const multipleLayoutCards = cards.map((c) =>
    getAuthValues(_.omit(c, ['singleLayout']), isAuthenticated),
  );

  return (
    <div className={styles.container}>
      <React.Fragment>
        <Box marginBottom="3-half">
          <Title size="small">{title}</Title>
        </Box>
        <div className={styles.cardsContainer}>
          {multipleLayoutCards.map((card, index) => (
            <SecondaryCampaignCard
              key={index}
              {...getAuthValues(card, isAuthenticated)}
            />
          ))}
        </div>
        <SecondaryCampaignCarousel tiles={multipleLayoutCards} />
      </React.Fragment>
    </div>
  );
};

export default SecondaryCampaignModuleContainer;
